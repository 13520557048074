<template>
  <div v-if="isBinded" class="line-module card-container">
    <SectionTitle title="Line 外掛模組" hideBtn />
    <el-form label-position="left">
      <el-form-item label="狀態">
        <div class="flex items-center" style="gap: 27px">
          <div class="flex items-center" style="gap: 4px">
            <Icon size="25">
              <CheckCircleFilled v-if="isBinded" />
              <CancelFilled v-if="!isBinded" />
            </Icon>

            <MaterialIcon v-if="isBinded" size="25" pointer>check_circle_filled</MaterialIcon>
            <MaterialIcon v-if="!isBinded" size="25" pointer>cancel_filled</MaterialIcon>

            <div>{{ isBinded ? '已綁定' : '未綁定' }}</div>
          </div>

          <a href="https://lin.ee/HVXuF8C" target="_blank" rel="noopener noreferrer">
            <el-button
              v-if="isBinded"
              class="underline"
              type="text"
              style="color: var(--danger)"
              @click="unbind"
            >
              申請解除綁定
            </el-button>
          </a>
        </div>
      </el-form-item>
    </el-form>
    <div v-if="isBinded" class="hint-area">
      <div class="text-primary-100">若欲申請解除綁定 Line 外掛模組，請點擊上方按鈕聯繫客服人員，並於聊天室中說明您的「組織名稱」、「分店名稱」及「欲解除綁定的原因」，將會有專人為您服務。</div>
      <div style="color: var(--danger)">請注意，試用期間一旦解除綁定，即屬試用期滿，恕無法再提供試用。</div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'LineModule',
  computed: {
    isBinded () {
      return get(this.$store.state.shop, 'shopConfig.LinemoduleBot.isBind') || false
    },
  },

}
</script>

<style lang="postcss" scoped>
.line-module {
  @apply p-[20px] pl-[29px];
}

.hint-area {
  @apply bg-primary-30 p-[8px];
}

::v-deep .el-form-item__label {
  @apply text-normal font-medium;
}
</style>
