<template>
  <section class="section-block">
    <SectionTitle title="Line Login Channel 設定" @edit="dialog.edit = true" />

    <el-form label-position="left" label-width="250px">
      <el-form-item label="Channel ID" prop="channelId">
        <div class="secret-text">
          <span>{{ config.channelId || '尚未設定' }}</span>
        </div>
      </el-form-item>
      <el-form-item label="Channel Secret" prop="channelSecret">
        <div class="secret-text">
          <span>{{ config.channelSecret || '尚未設定' }}</span>
        </div>
      </el-form-item>
      <el-form-item label="Callback URL">
        <div class="flex">
          <span>{{ redirectURL.line || '尚未填寫' }}</span>
          <img
            src="@/assets/icon/copy.svg"
            class="cursor-pointer"
            @click="copyUrl(redirectURL.line)"
          >
        </div>
      </el-form-item>
      <el-form-item label="外部瀏覽器不中斷綁定通知" prop="enableLoginBotLink">
        <div class="secret-text">
          <span>{{ config.enableLoginBotLink ? '啟用' : '關閉' }}</span>
        </div>
      </el-form-item>
    </el-form>

    <EditLineLoginDialog
      v-if="dialog.edit"
      :config="config"
      @close="dialog.edit = false"
      @refresh="getLineLoginConfig"
    />
  </section>
</template>

<script>
import EditLineLoginDialog from './EditLineLoginDialog.vue'
import { GetLineLoginConfig, GetLineLoginConfigRedirectUrl } from '@/api/line'
import { mapGetters } from 'vuex'
import copy from 'clipboard-copy'

export default {
  name: 'LineLoginSettings',
  components: { EditLineLoginDialog },
  data: () => ({
    config: {},
    dialog: {
      edit: false,
    },
    redirectURL: {
      line: null,
    },
  }),
  computed: {
    ...mapGetters(['shop', 'orgId']),
  },
  async mounted () {
    this.getLineLoginConfig()
    this.getLineLoginConfigRedirectUrl()
  },
  methods: {
    copyUrl (url) {
      copy(url)
      this.$message.success('已成功複製連結！')
    },
    async getLineLoginConfig () {
      const [res, err] = await GetLineLoginConfig({
        shopId: this.shop,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.config = res
    },
    async getLineLoginConfigRedirectUrl () {
      const [res, err] = await GetLineLoginConfigRedirectUrl({ shopId: this.shop })
      if (err) {
        this.$message.error(err)
        return
      }
      this.redirectURL.line = res.redirectUrl
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
